.volume-icon {
    color: white !important;
}
.volume-container {
    background-color: #1C1D1E;
    color: white;
    border-radius: 5px;
    height: 10vh;
    padding: 8px 0px;
}
.volume-slider {
    margin:10px 5px 10px 5px;
    height:100%;
    bottom:7px;
}
/* .volume-button {
    padding: 5px;
    border-radius: 5px;
}
.volume-button:hover {
    background-color: #404040;
} */
/* Messanger.js */
.messanger-card {
    border-radius:10px;
    height:45%;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    position:absolute;
    bottom:0;
    z-index:1;
}
.messanger-content {
    height:100%;
    padding:5px;
    overflow-y:auto;
    overflow-wrap:anywhere;
    background-color:#B0B0B0;
    display:flex;
    flex-direction:column
}
.messanger-content::-webkit-scrollbar{
    width:0.5rem
}
.messanger-content::-webkit-scrollbar-thumb {
    background-color:#fafafa;
    border-radius: 5px;
}
.messanger-content::-webkit-scrollbar-track  {
    box-sizing: inset 0 0 5px grey;
    border-radius: 5px;
}
.messanger-action {
    background-color:#B0B0B0;
}
.messanger-input {
    flex: 1;
    padding-block-start: 0px;
    font-size: 16px;
}
.messanger-icon-button{
    padding: 5px;
    color: #303030;
    border-radius: 0;
    border: 0;
}
.messanger-divider {
    height: 28;
    margin: 2;
}
.messanger-paper{
    width: 100%;
    padding: 2px 2px 2px 0;
    display: flex;
    align-items: center;
}
.messanger-avatar {
    background-color: #dc3545;
}
.messanger-receive {
    background-color:#F7F7F7;
    padding:2px 8px;
    margin:2px 5px 2px 0;
    width:fit-content;
    border-radius:10px 10px 10px 0px;
    justify-content:center;
    display:flex;
    float:left;
    flex-direction:column;
    color:#303030;
}
.messanger-send{
    background-color:#EDEDED;
    padding:2px 8px;
    margin:2px 0 2px 5px;
    color:#303030;
    width:fit-content;
    border-radius:10px 10px 0px 10px;
    justify-content:center;
    display:flex;
    float:right;
    flex-direction:column;
}
.messanger-time {
    display:absolute;
    float:right;
    bottom:0;
    width:fit-content;
}
.messanger-date {
    display:flex;
    align-items:center;
    justify-content:center;
    margin:5px;
}
.messanger-spinner {
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.messanger-mirrored-progress {
    transform: scaleX(-1)
}
/* SharedFiles.js */
.shared-file-item {
    width: 24%;
    height:200px;
    border-radius:5px;
    display:inline-grid;
    position: relative;
    margin-right:0.9%;
}
.shared-file-actions {
    display:flex;
    justify-content:space-between;
    align-items:end;
}
.shared-file-object {
    border: none;
    border-width: 0px;
    border-style: none;
    width: 100%;
    height: 100%;
}
/* ToolBox.js */

.toolbox-main {
    /* background-color:#1C1D1E !important; */
    position:absolute;
    bottom:0;
    /* border-radius:10px; */
    padding:5px;
    z-index: 3;
}

/* 
.toolbox-button:hover {
    background-color:#404040 !important
} */
/* .toolbox-btn-call {
    background-color:#0000FF !important;
    padding:5px;
    border-radius:5px;
}
.toolbox-btn-call:hover {
    background-color:#404040 !important;
} */
.toolbox-icon {
    color: white;
}
.toolbox-grid-devices {
    display: flex;
    position: relative;
}
.toolbox-btn-devices{
    padding:5px;
    border-radius:5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .toolbox-btn-devices:hover{
    background-color:#404040 !important
} */
.toolbox-option-devices{
display: flex;
position: absolute;
right: -5px;
top: -5px;
border-radius: 5px;
color: white !important;
}
.toolbox-option-devices:hover{
    cursor: pointer;
    background-color: white !important;
    color: #1C1D1E !important; 
}
