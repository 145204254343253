.root {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: white;
    flex-direction: column;
    margin: 0px;
    align-items: center;
}

.box.draggable-room-background {
    width: 90vw;
    height: 90vh;
    z-index: 2;
    box-shadow: 0.125rem 0 1.2rem rgb(4, 181, 250);
    border-radius: 6px;
}

.box.draggable-print-background {
    width: 630px;
    height: 300px;
    z-index: 2;
    box-shadow: 0.125rem 0 1.2rem rgb(4, 181, 250);
    border-radius: 6px;
}


.box.draggable-pwd-background {
    width: 56vh;
    z-index: 2;
    box-shadow: 0.125rem 0 1.2rem rgb(4, 181, 250);
    border-radius: 6px;
}

.show {
    visibility: visible;
}

.hide {
    visibility: hidden;
}

.lucas_logo_div {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    z-index: 1000;
}

.lucas_logo {
    position: absolute;
    left: 4.6vw;
    top: 5.5vh;
    width: 10%;
    z-index: 1;
}

.top_tap {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 35%;
}

.bottom_tap {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 61%;
}

.center_container {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcom_lab {
    font-family: SemiBold;
    font-size: 1.8vw;
    font-weight: bold;
    color: black;
    /* margin-bottom: 2vw; */
}

#welcom_sub_lab {
    color: #a1a1a1;
    /* margin-bottom: 1vw; */
    z-index: 1;
}

.sub_lab {
    font-family: SemiBold;
    font-size: 2.4vw;
    font-weight: bold;
    color: black;
    z-index: 1;
    margin: 0;
}

.main_lab {
    font-family: SemiBold;
    font-size: 4.1vw;
    font-weight: bold;
    color: black;
    z-index: 1;
    white-space: pre-line;
    text-align: center;
}

.bottom_div {
    display: flex;
    margin-top: 2em;
}

#call_div {
    position: absolute;
    right: 50px;
    bottom: 50px;
    text-transform: uppercase;
}

#back_div {
    position: absolute;
    left: 50px;
    bottom: 50px;
    text-transform: uppercase;
    /* width: 4.6vw;
    height: 2vw; */
}

.reception_tap {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 85%;
}

.left_container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    margin-left: 4.6vw;
}

.hello_div {
    display: flex;
    margin-top: 2vw;
    z-index: 1;
    height: 5vw;
    position: absolute;
    bottom: -6vw;
    justify-content: center;
    align-items: center;
}

.logout_div {
    position: absolute;
    top: 30px;
    right: 30px;
}

button.logout_btn {
    color: wheat;
    font-size: 1.2vw;
    font-weight: bold;
    font-family: 'SemiBold';
}

div.circle_progress {
    width: 100% !important;
    height: 100% !important;
    color: rgb(46, 43, 43);
}

div.circle_progress:hover {
    cursor: pointer;
    color: black;
}

.connecting_div {
    display: flex;
    position: relative;
}

.call_btn {
    font-size: 1.7vh;
    font-weight: bold;
}

.ok_btn {
    padding: 15px 35px !important;
    border-radius: 36px !important;
    font-size: 20px !important;
    outline: 0px !important;
    background: #21b830 !important;
    color: white !important;
    text-transform: none !important;
    border: 1px solid !important;
}

.cancel_btn {
    padding: 15px 24px !important;
    border-radius: 36px !important;
    font-size: 20px !important;
    outline: 0px !important;
    background: #ff7a00 !important;
    color: white !important;
    text-transform: none !important;
    border: 1px solid !important;
}

.volume_div {
    display: flex;
    justify-content: flex-end;
    width: 35%;
    margin-left: auto;
}

.call_connnect_status {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white;
    padding: 1px 5px;
    font-family: Poppins;
    background: #262626;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
}

.iframe_container {
    width: 70%;
    height: 60%;
    z-index: 1;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vw;
    margin-left: 1vw;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #a1a1a1;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
